<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="11"
        lg="9"
        xl="8"
      >
        <v-card outlined>
          <v-card-text>
            <v-btn
              depressed
              small
              class="me-4"
              :color="tab == 0 ? 'primary' : ''"
              @click="tab = 0"
            >
              {{ $t('Active') }}
            </v-btn>

            <v-btn
              depressed
              small
              class="me-4"
              :color="tab == 1 ? 'primary' : ''"
              @click="tab = 1"
            >
              {{ $t('Archive') }}
            </v-btn>

            <ActiveSocial v-if="tab === 0" />

            <ArchivedSocial v-if="tab === 1" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ActiveSocial from './sub/ActiveSocial.vue'
import ArchivedSocial from './sub/ArchivedSocial.vue'

export default {
  components: {
    ActiveSocial,
    ArchivedSocial,
  },

  data() {
    return {
      tab: 0
    }
  },

}
</script>

<style lang="scss" scoped>
</style>
